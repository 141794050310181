import { css } from 'styled-components';

import { mq } from '@/shared/ui/styled';

export const flexRow = css`
    display: flex;
    flex-direction: row;
`;

export const flexColumn = css`
    display: flex;
    flex-direction: column;
`;

export const rowBetween = css`
    display: flex;
    justify-content: space-between;
`;

export const rowMiddle = css`
    display: flex;
    justify-content: center;
`;

export const rowYStart = css`
    display: flex;
    align-items: flex-start;
`;

export const rowYMiddle = css`
    display: flex;
    align-items: center;
`;

export const rowBothMiddle = css`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const rowXBetweenYCenter = css`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const flexCol = css`
    display: flex;
    flex-direction: column;
`;

export const colMiddle = css`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const colXMiddle = css`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const colBothMiddle = css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const rowEnd = css`
    display: flex;
    justify-content: flex-end;
`;

export const colEnd = css`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
`;

export const rowXEndYStart = css`
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
`;

export const backgroundRadiusBlock = css`
    background: ${({ theme }) => theme.color.secondary};
    border-radius: 12px;
    padding: 24px;

    ${mq.lt.md} {
        padding: 10px;
    }
`;

export const defaultSettingsHtml = css`
    html,
    address,
    blockquote,
    body,
    dd,
    div,
    dl,
    dt,
    fieldset,
    form,
    frame,
    frameset,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    ol,
    p,
    ul,
    center,
    dir,
    hr,
    menu,
    pre {
        display: block;
        unicode-bidi: embed;
    }

    li {
        display: list-item;
        list-style: inherit;
    }

    head {
        display: none;
    }

    table {
        display: table;
        border-spacing: 2px;
    }

    tr {
        display: table-row;
    }

    thead {
        display: table-header-group;
    }

    tbody {
        display: table-row-group;
    }

    tfoot {
        display: table-footer-group;
    }

    col {
        display: table-column;
    }

    colgroup {
        display: table-column-group;
    }

    td,
    th {
        display: table-cell;
    }

    caption {
        display: table-caption;
        text-align: center;
    }

    th {
        font-weight: bolder;
        text-align: center;
    }

    body {
        margin: 8px;
    }

    h1 {
        font-size: 2em;
        margin: 0.67em 0;
        font-weight: 500;
    }

    h2 {
        font-size: 1.5em;
        margin: 0.75em 0;
        font-weight: 500;
    }

    h3 {
        font-size: 1.17em;
        margin: 0.83em 0;
        font-weight: 500;
    }

    h4 {
        font-weight: 500;
    }

    h4,
    p,
    blockquote,
    ul,
    fieldset,
    form,
    ol,
    dl,
    dir,
    menu {
        margin: 1.12em 0;
    }

    h5 {
        font-size: 0.83em;
        margin: 1.5em 0;
        font-weight: 500;
    }

    h6 {
        font-size: 0.75em;
        margin: 1.67em 0;
        font-weight: 500;
    }

    b,
    strong {
        font-weight: bolder;
    }

    blockquote {
        margin-left: 40px;
        margin-right: 40px;
    }

    i,
    cite,
    em,
    var,
    address {
        font-style: italic;
    }

    pre,
    tt,
    code,
    kbd,
    samp {
        font-family: monospace;
    }

    pre {
        white-space: pre;
    }

    button,
    textarea,
    input,
    select {
        display: inline-block;
    }

    big {
        font-size: 1.17em;
    }

    small,
    sub,
    sup {
        font-size: 0.83em;
    }

    sub {
        vertical-align: sub;
    }

    sup {
        vertical-align: super;
    }

    thead,
    tbody,
    tfoot {
        vertical-align: middle;
    }

    td,
    th,
    tr {
        vertical-align: inherit;
    }

    s,
    strike,
    del {
        text-decoration: line-through;
    }

    hr {
        border: 1px inset;
    }

    ol,
    ul,
    dir,
    menu,
    dd {
        margin-left: 40px;
        list-style: inherit;
    }

    ol {
        list-style-type: decimal;
    }

    ol ul,
    ul ol,
    ul ul,
    ol ol {
        margin-top: 0;
        margin-bottom: 0;
        list-style: inherit;
    }

    u,
    ins {
        text-decoration: underline;
    }

    br::before {
        content: '\A';
        white-space: pre-line;
    }

    center {
        text-align: center;
    }

    :link,
    :visited {
        text-decoration: underline;
    }

    :focus {
        outline: thin dotted invert;
    }

    bdo[DIR='ltr'] {
        direction: ltr;
        unicode-bidi: bidi-override;
    }

    bdo[DIR='rtl'] {
        direction: rtl;
        unicode-bidi: bidi-override;
    }

    *[DIR='ltr'] {
        direction: ltr;
        unicode-bidi: embed;
    }

    *[DIR='rtl'] {
        direction: rtl;
        unicode-bidi: embed;
    }

    @media print {
        h1 {
            page-break-before: always;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            page-break-after: avoid;
        }

        ul,
        ol,
        dl {
            page-break-before: avoid;
        }
    }
`;
